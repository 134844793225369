import React from "react";
import theme from "theme";
import { Theme, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"privacy"} />
		<Helmet>
			<title>
				Energy Fitness Center
			</title>
			<meta name={"description"} content={"Gücünüzü Yükseltin"} />
			<meta property={"og:title"} content={"Energy Fitness Center"} />
			<meta property={"og:description"} content={"Gücünüzü Yükseltin"} />
			<meta property={"og:image"} content={"https://etherealbreath.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://etherealbreath.com/img/2749777.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://etherealbreath.com/img/2749777.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://etherealbreath.com/img/2749777.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://etherealbreath.com/img/2749777.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://etherealbreath.com/img/2749777.png"} />
			<meta name={"msapplication-TileImage"} content={"https://etherealbreath.com/img/2749777.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 120px 0" quarkly-title="Partners-2">
			<Text margin="0px 0px 20px 0px" text-align="center" font="normal 500 56px/1.2 --fontFamily-sans" color="--primary">
			Gizlilik Politikası
			</Text>
			<Text
				margin="0px 0px 20px 0px"
				text-align="left"
				font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
				color="#606469"
				sm-margin="0px 0px 50px 0px"
			>
				Gizliliğiniz bizim için çok önemlidir. İnternet’teki çalışmalarınızın olabildiğince keyifli ve kullanışlı olmasını istiyoruz ve İnternet’in sunduğu en geniş bilgi, araç ve fırsatları tamamen sakin bir şekilde kullanıyorsunuz.
				<br />
				<br />
				( kaydı sırasında veya başka bir zamanda ) toplanan Üyelerin kişisel bilgileri esas olarak ihtiyaçlarınıza göre Ürün veya Hizmet hazırlamak için kullanılır. Bilgileriniz üçüncü taraflara aktarılmayacak veya satılmayacaktır. Bununla birlikte, “ Posta ile rıza ” ‘de açıklanan özel durumlarda kişisel bilgileri kısmen açıklayabiliriz
			</Text>
			<Text margin="0px 0px 20px 0px" text-align="left" font="--headline2" color="--darkL1">
			Sitede hangi veriler toplanıyor
			</Text>
			<Text
				margin="0px 0px 20px 0px"
				text-align="left"
				font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
				color="#606469"
				sm-margin="0px 0px 50px 0px"
			>
				Posta için gönüllü olarak kaydolduğunuzda, Adınızı ve E-postanızı kayıt formu aracılığıyla gönderirsiniz.
			</Text>
			<Text margin="0px 0px 20px 0px" text-align="left" font="--headline2" color="--darkL1">
			Bu veriler hangi amaçla toplanıyor
			</Text>
			<Text
				margin="0px 0px 20px 0px"
				text-align="left"
				font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
				color="#606469"
				sm-margin="0px 0px 50px 0px"
			>
				İsim sizinle kişisel olarak iletişim kurmak için kullanılır ve e-postanız size bülten mektupları, haberler, faydalı materyaller, ticari teklifler göndermek için kullanılır.

				<br />
				<br />
				
Adınız ve e-postanız, yasal gerekliliklerin yerine getirilmesi ile ilgili durumlar dışında hiçbir koşulda üçüncü taraflara aktarılmaz.
	<br/><br/>
	Her harfteki abonelikten çıkma bağlantısını tıklayarak posta mektuplarını almayı reddedebilir ve iletişim bilgilerinizi veritabanından istediğiniz zaman silebilirsiniz.
			</Text>
			<Text margin="0px 0px 20px 0px" text-align="left" font="--headline2" color="--darkL1">
			Bu veriler nasıl kullanılır
			</Text>
			<Text
				margin="0px 0px 20px 0px"
				text-align="left"
				font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
				color="#606469"
				sm-margin="0px 0px 50px 0px"
			>
				Bu verileri kullanarak, içeriğini iyileştirmek, sitenin işlevselliğini geliştirmek ve sonuç olarak ziyaretçiler için yüksek kaliteli içerik ve hizmetler oluşturmak amacıyla ziyaretçilerin sitedeki eylemleri hakkında bilgi toplanır.
				<br/>
				<br/>
				Tarayıcınızın tüm dosyaları engellemesi veya bu dosyaları göndermeniz konusunda sizi bilgilendirmesi için tarayıcınızın ayarlarını istediğiniz zaman değiştirebilirsiniz. Bazı işlevlerin ve hizmetlerin düzgün çalışamayacağını lütfen unutmayın.
			</Text>
			<Text margin="0px 0px 20px 0px" text-align="left" font="--headline2" color="--darkL1">
			Bu veriler nasıl korunuyor
			</Text>
			<Text
				margin="0px 0px 70px 0px"
				text-align="left"
				font="normal 400 20px/1.5 --fontFamily-sansHelvetica"
				color="#606469"
				sm-margin="0px 0px 50px 0px"
			>
				Kişisel bilgilerinizi korumak için çeşitli idari, yönetsel ve teknik güvenlik önlemleri kullanıyoruz. Şirketimiz, internette toplanan bilgileri korumak için belirli kontrol önlemlerini içeren kişisel bilgilerle operasyonlara yönelik çeşitli uluslararası kontrol standartlarına uyar.
				<br/>
Çalışanlarımız bu kontrol önlemlerini anlamak ve uygulamak için eğitilmişlerdir, Gizlilik Bildirimimize, normlarımıza ve talimatlarımıza aşinadırlar.

<br/>
<br/>
Bununla birlikte, kişisel bilgilerinizi güvence altına almaya çalıştığımıza rağmen, onu korumak için de önlemler almalısınız.

<br/>
				<br/>
İnternette kalırken mümkün olan tüm önlemleri almanızı önemle tavsiye ederiz. Düzenlediğimiz hizmetler ve web siteleri, sızıntıya, yetkisiz kullanıma ve kontrol ettiğimiz bilgi değişikliğine karşı koruma önlemleri içerir. Ağımızın ve sistemlerimizin bütünlüğünü ve güvenliğini sağlamak için mümkün olan her şeyi yapmamıza rağmen, garanti edemeyiz, güvenlik önlemlerimizin üçüncü taraf kuruluşların bilgisayar korsanları tarafından bu bilgilere yasa dışı erişimin önlenmesine yardımcı olacağını.
				<br/>
				<br/>

Bu gizlilik politikası değiştirilirse, bu değişiklikleri bu sayfada okuyabilir veya özel durumlarda e-postanız hakkında bir bildirim alabilirsiniz.
			</Text>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});